import { OrderForRoute, OrderPointsForRoute } from "api/orders/models";
import { Route } from "api/routes/models";
import crossImg from "assets/images/11.svg";
import sendSmsIcon from "assets/images/174.svg";
import deliveryConfirmedIcon from "assets/images/175.svg";
import availabilityStatusIcon from "assets/images/176.svg";
import carImg from "assets/images/24.svg";
import pinImg from "assets/images/32g.svg";
import furnitureImg from "assets/images/33.svg";
import handSetDeliveryIcon from "assets/images/approvalDelegation.svg";
import pin2Img from "assets/images/42.svg";
import commentImg from "assets/images/45.svg";
import timeImg from "assets/images/46.svg";
import dollarIcon from "assets/images/monetizationOn.svg";
import checkImg from "assets/images/7c.svg";
import redCrossIcon from "assets/images/11r.svg";
import cx from "classnames";
import { Button } from "components/common";
import { AvailabilityDetailsModal } from "components/common/availabilityDetailsModal";
import { getLabelColor } from "components/common/listRow/utils";
import { CommonError, Label, Spinner, StatusHandler } from "components/utils";
import { useConfirmModal, useSelector, useStateModal, useToastr, useToggle } from "hooks";
import { useOrderForRoute, usePinToRouteHistory } from "hooks/apiPrimitives";
import { Link } from "react-router-dom";
import { dateFns, getAnyErrorKey, pluralize } from "utilities";
import { getMarkerIcon } from "utilities/getMarkerIcon";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { useAssignItem } from "../../hooks/useAssignItem";
import { useUnassignOrder } from "../../hooks/useUnassignOrder";
import { useRouteViewState } from "../../routeCreatorState";
import styles from "./InfoWindowContent.module.css";
import { getAwaitingLabelColor } from "./utils";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { areConfirmedHoursInCollectionRange } from "../../leftPanel/pointsList/utils/areConfirmedHoursInCollectionRange";
import { getPinToRouteSearch } from "./utils/getPinToRouteSearch";
import { PinnedOrderInfo } from "./components/PinnedOrderInfo";
import { UnpinnedOrderInfo } from "./components/UnpinnedOrderInfo";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { useRemoveDeliveryConfirmation } from "../hooks/useRemoveDeliveryConfirmation";
import { TimePicker } from "components/utils/timePicker";
import { usePatchDeliveryMutation } from "../hooks/usePatchDeliveryMutation";
import { HidePointModal } from "./hidePointModal/HidePointModal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useMutation } from "hooks/useMutation";
import { patchReverseHidingPointOnMap } from "api/routes/calls";
import { routeKeys } from "api/keys";
import immer from "immer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiWithBringing } from "components/miloDesignSystem/atoms/icons/MdiWithBringing";
import { MdiMontage } from "components/miloDesignSystem/atoms/icons/MdiMontage";

interface Props {
  id: string;
  route: Route;
  routePointsSearch: string;
}

export const InfoWindowContent = ({ id, route, routePointsSearch }: Props) => {
  const { data: order, isLoading: inProgress, error, refetch } = useOrderForRoute(id);
  const hasSmsService = useSelector(store => store.partials.configuration.hasSmsService);
  const isPinned = route?.orders.some(order => order.id === Number(id));
  const search = getPinToRouteSearch(id, isPinned);
  const {
    data: pinnedOrders,
    error: pinnedOrdersError,
    isLoading: pinnedOrdersIsLoading,
  } = usePinToRouteHistory(search);
  const modal = useStateModal();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);
  const { unassignOrder } = useUnassignOrder(route);
  const { assignOrder } = useAssignItem(route);
  const confirmModal = useConfirmModal();
  const removeDeliveryConfirmationMutation = useRemoveDeliveryConfirmation();
  const minimumCustomDeliveryTimeMutation = usePatchDeliveryMutation();
  const maximumCustomDeliveryTimeMutation = usePatchDeliveryMutation();
  const hidePointModal = useToggle();
  const toastr = useToastr();

  const restoreOrdersVisibilityMutation = useMutation(
    ({ toUpdate }: { toUpdate: { orderId: number } }) => patchReverseHidingPointOnMap(toUpdate),
    ({ queryClient, toastr }) => ({
      onSuccess: (_, args) => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Przywrócono punkt",
        });
        refetch();
        queryClient.setQueryData<OrderPointsForRoute>(
          routeKeys.routeCreatorPoints(routePointsSearch),
          currentList => {
            assertIsDefined(currentList);
            return immer(currentList, draft => {
              draft.results = draft.results.map(orderPoint => {
                if (orderPoint.id === args.toUpdate.orderId) {
                  return {
                    ...orderPoint,
                    isHidden: false,
                  };
                }
                return orderPoint;
              });
            });
          },
        );
      },
    }),
  );

  if (error || pinnedOrdersError) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <CommonError text={getAnyErrorKey(error)} />
        </div>
      </div>
    );
  }

  if (inProgress || pinnedOrdersIsLoading) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100 pt-5">
          <Spinner color="blue" size="big" />
        </div>
      </div>
    );
  }

  if (!order || !pinnedOrders) return null;

  return (
    <div className={styles["map-point-cloud"]}>
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className={styles["point-image"]}>
              <img
                src={getMarkerIcon({
                  ...order,
                  hideOnMapTo: order.delivery.hideOnMapTo,
                  isHidden: order.delivery.isHidden,
                  hideUntilIssueIsSolved: order.delivery.hideUntilIssueIsSolved,
                })}
                alt=""
              />
            </div>
            <div>
              {isAwaitingForWarehouseDelivery(order) &&
              order.warehouseDeliveryDetails.daysLeftToDelivery !== null ? (
                <Label
                  color={getAwaitingLabelColor(order.warehouseDeliveryDetails.daysLeftToDelivery)}
                  className="mr-3 ml-2"
                >
                  {order.warehouseDeliveryDetails.daysLeftToDelivery === 0 ? (
                    <span>dzisiaj</span>
                  ) : (
                    <span>
                      {order.warehouseDeliveryDetails.daysLeftToDelivery}{" "}
                      {pluralize.pl(order.warehouseDeliveryDetails.daysLeftToDelivery, {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      })}
                    </span>
                  )}
                </Label>
              ) : (
                <Label
                  color={getLabelColor(
                    order.type === "STANDARD" ? order.leftDays : order.numberOfDaysFromCreatedDate,
                    order.type,
                  )}
                  className="mr-3 ml-2"
                >
                  <span>
                    {order.type === "STANDARD" ? order.leftDays : order.numberOfDaysFromCreatedDate}{" "}
                    {pluralize.pl(
                      order.type === "STANDARD"
                        ? order.leftDays
                        : order.numberOfDaysFromCreatedDate,
                      {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      },
                    )}
                  </span>
                </Label>
              )}
            </div>
          </div>
          <small className={styles.createdDate}>
            zam. dn.: {dateFns.format(new Date(order.created), "dd.MM.yyyy")}
          </small>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              {isPinned ? (
                <StatusHandler>
                  {helpers => (
                    <Button
                      kind="secondary"
                      size="small"
                      className="pl-2 pr-2 pt-1 pb-1 ml-2"
                      disabled={!route || helpers.isFetching || isLoading}
                      onClick={() => {
                        actions.openLoader("Trwa odpinanie punktu");
                        unassignOrder(order.id, "order", helpers);
                      }}
                    >
                      <img className="mr-2" src={pinImg} alt="" />
                      <span>Odepnij</span>
                    </Button>
                  )}
                </StatusHandler>
              ) : (
                <StatusHandler>
                  {helpers => (
                    <Button
                      kind="secondary"
                      size="small"
                      className="pl-2 pr-2 pt-1 pb-1 ml-2"
                      disabled={!route || helpers.isFetching || isLoading}
                      onClick={() => {
                        if (!order.delivery.isHidden) {
                          actions.openLoader("Trwa przypinanie punktu");
                          assignOrder(
                            {
                              id: order.id,
                              point: order.delivery.point,
                              warehouse: order.warehouse,
                              isAssemblingRequested: order.isAssemblingRequested,
                              hasCarrying: order.hasCarrying,
                              type: "order",
                              address: null,
                              warehouseDeliveryDetails: order.warehouseDeliveryDetails,
                              hasGuaranteedDeliveryBeforeChristmas:
                                order.hasGuaranteedDeliveryBeforeChristmas,
                            },
                            helpers,
                          );
                        }
                        if (order.delivery.isHidden) {
                          toastr.open({
                            type: "warning",
                            title: "Wymagane działanie",
                            text: `Punkt jest ukryty do ${
                              order.delivery.hideOnMapTo
                                ? dateFns.format(
                                    new Date(order.delivery.hideOnMapTo),
                                    "dd.MM.yyyy, H:mm",
                                  )
                                : "czasu rozwiązania problemu"
                            }. ${Boolean(order.delivery.hideIssue.length) &&
                              `Powód: ${order.delivery.hideIssue}`}`,
                          });
                        }
                      }}
                    >
                      <img className="mr-2" src={pinImg} alt="" />
                      <span>Przypnij</span>
                    </Button>
                  )}
                </StatusHandler>
              )}
            </div>
            {hasSmsService && (
              <div className="d-flex align-items-center justify-content-end ml-2">
                <span
                  className={cx(
                    styles.smsNotification,
                    "mr-1",
                    order.hasSmsSent ? "bgPurple" : "bgGrey",
                  )}
                >
                  <img alt="" src={sendSmsIcon} />
                </span>
                <span
                  className={cx(
                    styles.smsNotification,
                    order.isDeliveryDateConfirm ? "bgBlue" : "bgGrey",
                  )}
                >
                  <img alt="" src={deliveryConfirmedIcon} />
                </span>
              </div>
            )}
            <div className="ml-1">
              {!order.delivery.isHidden ? (
                <DesignSystemButton
                  className={styles.hideRoutePointBtn}
                  onClick={() => {
                    if (isPinned) {
                      toastr.open({
                        type: "warning",
                        title: "Wymagane działanie",
                        text: "Nie można ukryć punktu przypiętego do trasy",
                      });
                    } else {
                      hidePointModal.open();
                    }
                  }}
                  size="small"
                  variant="outline"
                >
                  Ukryj punkt
                </DesignSystemButton>
              ) : (
                <div className="d-flex flex-column">
                  <div className="pl-2">
                    <Typography
                      className={styles.hideRoutePointTypography}
                      fontSize="14"
                      fontWeight="700"
                    >
                      Ukryto do{" "}
                      {order.delivery.hideOnMapTo
                        ? dateFns.format(new Date(order.delivery.hideOnMapTo), "dd.MM.yyyy, H:mm")
                        : "rozwiązania"}
                    </Typography>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <DesignSystemButton
                      className={styles.changeRoutePointHideStatusBtn}
                      onClick={() => {
                        if (isPinned) {
                          toastr.open({
                            type: "warning",
                            title: "Wymagane działanie",
                            text: "Nie można ukryć punktu przypiętego do trasy",
                          });
                        } else {
                          hidePointModal.open();
                        }
                      }}
                      size="small"
                      variant="transparent"
                    >
                      Zmień
                    </DesignSystemButton>
                    <DesignSystemButton
                      className={styles.changeRoutePointHideStatusBtn}
                      onClick={() =>
                        restoreOrdersVisibilityMutation.mutate({
                          toUpdate: {
                            orderId: order.id,
                          },
                        })
                      }
                      size="small"
                      variant="transparent"
                    >
                      Przywróć widoczność
                    </DesignSystemButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 mt-2 row">
        <div className="col-6 pl-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={pin2Img} alt="" />
              <span>
                {!order.customer
                  ? `${order.delivery.firstName} ${order.delivery.lastName}`
                  : order.customer.hasDropShipping
                  ? `${order.delivery.firstName} ${order.delivery.lastName} (${order.customer.name})`
                  : order.customer.name}
              </span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.delivery.street} <br /> {order.delivery.city} {order.delivery.postCode}
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>tel.: {order.delivery.phone}</div>
            <div
              className={cx(styles["map-cloud_group-data"], "d-flex align-items-center gap-1 mt-1")}
            >
              {order.hasCarrying && (
                <Tag label="Wniesienie" variant="info" startIcon={MdiWithBringing} />
              )}
              {order.isAssemblingRequested && (
                <Tag label="Montaż" variant="warning" startIcon={MdiMontage} />
              )}
            </div>
          </div>
          {order.payment.type === "CASH_ON_DELIVERY" && (
            <div className="d-flex align-items-center gap-2 mb-3 pl-4">
              <img alt="płatność:" src={dollarIcon} style={{ height: "16px", width: "16px" }} />
              <span className="fs-14 text-color-grey">POBRANIE</span>
            </div>
          )}
        </div>
        <div className="col-6 pr-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={commentImg} alt="" />
              <span>Komentarze</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.comments.length ? (
                order.comments.map(comment => (
                  <div key={comment.id}>
                    <div className={styles.hasComment}>{comment.body}</div>
                    <div className={styles.usersComment}>
                      <strong className="mr-2">
                        {comment.user.firstName} {comment.user.lastName}
                      </strong>
                      <span className="mr-2">
                        {dateFns.format(new Date(comment.created), "d MMM yyyy")}
                      </span>
                      <span>{dateFns.format(new Date(comment.created), "H:mm")}</span>
                    </div>
                  </div>
                ))
              ) : (
                <span className={styles.noComment}>Brak komentarzy</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 row">
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pl-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={carImg} alt="" />
            <span>Przewidywany czas dostawy</span>
          </div>
          <div
            className={cx(styles["map-cloud_group-data"], {
              [styles.correctConfirmationRange]:
                order.delivery.maximumCollectionHour &&
                order.delivery.minimumCollectionHour &&
                areConfirmedHoursInCollectionRange(
                  order.delivery.maximumCollectionHour,
                  order.delivery.minimumCollectionHour,
                  order.maximumCollectionHourAtConfirmation,
                  order.minimumCollectionHourAtConfirmation,
                ) === true,
              [styles.invalidConfirmationRange]:
                order.delivery.maximumCollectionHour &&
                order.delivery.minimumCollectionHour &&
                areConfirmedHoursInCollectionRange(
                  order.delivery.maximumCollectionHour,
                  order.delivery.minimumCollectionHour,
                  order.maximumCollectionHourAtConfirmation,
                  order.minimumCollectionHourAtConfirmation,
                ) === false,
            })}
          >
            {order.collectionHours ? (
              <>
                <span>{order.collectionHours.minimumCollectionHour || "--:--"}</span>
                <span className="mx-2">-</span>
                <span>{order.collectionHours.maximumCollectionHour || "--:--"}</span>
              </>
            ) : (
              <>
                <span className={styles.noComment}>--:--</span>
                <span className="mx-2">-</span>
                <span className={styles.noComment}>--:--</span>
              </>
            )}
          </div>
        </div>
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pr-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={timeImg} alt="" />
            <span>Potwierdzony czas dostawy</span>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.minimumCollectionHourAtConfirmation &&
            order.minimumCollectionHourAtConfirmation.length > 0 ? (
              <span>{order.minimumCollectionHourAtConfirmation}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
            <span className="mx-2">-</span>
            {order.maximumCollectionHourAtConfirmation &&
            order.maximumCollectionHourAtConfirmation.length > 0 ? (
              <span>{order.maximumCollectionHourAtConfirmation}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
          </div>
          {order.minimumCollectionHourAtConfirmation && order.maximumCollectionHourAtConfirmation && (
            <div className={cx(styles["map-cloud_group-data"])}>
              <DesignSystemButton
                className="text-uppercase"
                onClick={() => {
                  const minimumHour = order.minimumCollectionHourAtConfirmation;
                  const maximumHour = order.maximumCollectionHourAtConfirmation;
                  if (minimumHour !== undefined && maximumHour !== undefined) {
                    confirmModal.open({
                      text: `Teraz usuniesz godziny dostawy potwierdzone przez klienta (${order.minimumCollectionHourAtConfirmation} - ${order.maximumCollectionHourAtConfirmation}). Czy na pewno chcesz to zrobić?`,
                      confirmText: "Usuń potwierdzenie",
                      callback: () =>
                        removeDeliveryConfirmationMutation.mutate({
                          id: order.id,
                          minimumHour: minimumHour,
                          maximumHour: maximumHour,
                          route: route.id,
                        }),
                    });
                  }
                }}
                size="small"
                variant="gray"
              >
                Usuń potwierdzenie
              </DesignSystemButton>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap m-0 row">
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-12 pl-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img alt="" src={handSetDeliveryIcon} style={{ height: 16, width: 16 }} />
            <span>Ręcznie ustawiony przedział dostawy</span>
          </div>
          <div className={styles["map-cloud_group-data"]}>
            <div className="d-flex align-items-center">
              <TimePicker
                look="common"
                onBlur={time =>
                  minimumCustomDeliveryTimeMutation.mutate({
                    id: order.delivery.id,
                    toUpdate: {
                      minimumCustomDeliveryHour: time || null,
                    },
                    routeId: route.id,
                  })
                }
                value={order.delivery.minimumCustomDeliveryHour || ""}
              />
              <span className="mx-2">-</span>
              <TimePicker
                look="common"
                onBlur={time =>
                  maximumCustomDeliveryTimeMutation.mutate({
                    id: order.delivery.id,
                    toUpdate: {
                      maximumCustomDeliveryHour: time || null,
                    },
                    routeId: route.id,
                  })
                }
                value={order.delivery.maximumCustomDeliveryHour || ""}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap mt-2">
        <div
          className={cx(styles["map-cloud_data-group"], "map-cloud_data-group--product-list mb-3")}
        >
          {order.hasProducedItems ? (
            <div className={styles.hasProducedItems}>
              <img src={checkImg} alt="" className="mr-2" />
              <span>Wszystkie meble są przygotowane</span>
            </div>
          ) : null}
          <div className={cx(styles["map-cloud_group-label"])}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center">
                <img src={furnitureImg} alt="" />
                <span>Dostarczyć zamówienie</span>
              </div>
              <div className="d-flex flex-column gap-2">
                {order.hasReservations ? (
                  <Label color="blue">zarezerwowano</Label>
                ) : (
                  <Label color="pink">Nie zarezerwowano</Label>
                )}
                <div>
                  <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                    całkowity koszt transportu:
                  </Typography>
                  <div className="d-flex align-items-baseline gap-1">
                    <Typography fontSize="14" fontWeight="600">
                      {order.totalItemsTransportAmount}
                    </Typography>
                    <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                      {order.currency}
                    </Typography>
                  </div>
                  <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                    całkowity koszt wniesienia:
                  </Typography>
                  <div className="d-flex align-items-baseline gap-1">
                    <Typography fontSize="14" fontWeight="600">
                      {order.totalItemsCarryingAmount}
                    </Typography>
                    <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                      {order.currency}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.externalId ? (
              <div>
                <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1 mr-1")}>
                  {order.signature}
                </Link>
                <span className="fs-12 text-color-grey">({order.externalId})</span>
              </div>
            ) : (
              <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1")}>
                {order.signature}
              </Link>
            )}
            {order.items.map(item => (
              <div className="product d-flex align-items-center mb-2" key={item.id}>
                <div>
                  <div className="d-flex product-name mr-3">
                    {item.product ? item.product.name : "???"}
                    <div className="d-flex align-items-center ml-1">
                      <img className={styles.xIcon} src={crossImg} alt="" />
                      <span className="ml-1">{item.quantity}</span>
                    </div>
                    <Button
                      type="button"
                      kind="secondary"
                      size="round-s"
                      className="ml-1"
                      onClick={() => modal.open(item.index)}
                      title="Sprawdź dostępność"
                    >
                      <img alt="" src={availabilityStatusIcon} />
                    </Button>
                  </div>
                  {item.attributes.map(
                    ({ attribute, value }: { attribute: string; value: string }, index: number) => (
                      <div className={styles.attribute} key={index}>
                        <span>{attribute}: </span>
                        <strong>{value}</strong>
                      </div>
                    ),
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isPinned && pinnedOrders.length === 1 && <PinnedOrderInfo pinnedLog={pinnedOrders[0]} />}

      {!isPinned && pinnedOrders.length > 0 && <UnpinnedOrderInfo unpinnedLogs={pinnedOrders} />}

      {order.isDeliveryDateRejected && (
        <div className="d-flex flex-wrap m-0 row border-top pt-2">
          <div className={cx(styles["map-cloud_data-group"], "col-12")}>
            <div className={cx(styles["map-cloud_group-label-red"])}>
              <img src={redCrossIcon} alt="Odrzucono" />
              <span>Odrzucono</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"], "ml-4")}>
              {order.deliveryDateRejectedByAvatar ||
              order.deliveryDateRejectedByFirstName ||
              order.deliveryDateRejectedByLastName ||
              order.deliveryDateRejectedTimestamp ? (
                <div className="d-flex align-items-center gap-2 mt-1">
                  <AvatarOrInitials
                    avatarSrc={order.deliveryDateRejectedByAvatar}
                    firstName={order.deliveryDateRejectedByFirstName}
                    lastName={order.deliveryDateRejectedByLastName}
                    className={styles.midAvatar}
                  />
                  <div className="d-flex align-items-center gap-2">
                    <div>
                      {order.deliveryDateRejectedByFirstName} {order.deliveryDateRejectedByLastName}
                    </div>
                    {order.deliveryDateRejectedTimestamp && (
                      <div className="fs-12 text-color-grey">
                        {dateFns.format(
                          new Date(order.deliveryDateRejectedTimestamp),
                          "dd.MM.yyyy, HH:mm",
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <span className={styles.noComment}>--</span>
              )}
            </div>
          </div>
        </div>
      )}
      {modal.isOpen && <AvailabilityDetailsModal stateModal={modal} />}
      {hidePointModal.isOpen && (
        <HidePointModal
          close={hidePointModal.close}
          order={order}
          refetch={refetch}
          routePointsSearch={routePointsSearch}
        />
      )}
    </div>
  );
};

const isAwaitingForWarehouseDelivery = (order: OrderForRoute): boolean => {
  return (
    order.warehouseDeliveryDetails &&
    !order.warehouseDeliveryDetails.isInWarehouse &&
    order.warehouseDeliveryDetails.date !== null
  );
};
