import { dateFns } from "utilities";
import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import {
  ManufacturerOrders,
  ManufacturerOrder,
  ProductionOrderIndex,
  ManufacturerOrderLinkInfo,
  ProductionOrdersCreationByHand,
} from "./models";
import { Order, OrderIndex } from "../orders/models";
import { Pagination } from "../types";
import { queryFetch } from "apiConnectors/queryFetch";

const api = tokenRefreshMiddleware(apiFetch);

export const getManufacturerOrders = (search: string = "", abortToken?: string) =>
  api<Pagination<ManufacturerOrders>>({
    method: "GET",
    url: "/production-orders/for-manufacturer" + search,
    abortToken,
  });

export const getManufacturerOrder = (id: string | number, abortToken?: string) =>
  api<ManufacturerOrder>({
    method: "GET",
    url: "/production-orders/for-manufacturer/" + id,
    abortToken,
  });

export const patchManufacturerOrder = (id: number | string, data: Partial<ManufacturerOrder>) =>
  api<ManufacturerOrder>({
    method: "PATCH",
    url: "/production-orders/for-manufacturer/" + id,
    data,
  });
export const manufacturerOrderBulkUpdate = (
  id: number | string,
  data: { isReceivedFromManufacturer: boolean },
) =>
  api<ManufacturerOrder>({
    method: "PATCH",
    url: "/production-orders/for-manufacturer/" + id + "/bulk-update",
    data,
  });

export const getItemsForCreateManufacturerOrder = (data: { orders: number[] }) =>
  api<OrderIndex[]>({
    method: "POST",
    url: "/production-orders/prepare-for-creating-production-orders",
    data,
  });

export const createManufacturerOrder = (data: {
  items: {
    id: number;
    indexId: number;
    number: number;
    manufacturer: number | null;
    isSelfProduced: boolean;
    externalNumber: string;
  }[];
}) =>
  api<{
    status: string;
    manufacturersOrders: Order["recentManufacturersOrders"];
    productionOrders: Order["recentProductionOrders"][number];
  }>({
    method: "POST",
    url: "/production-orders/create-production-orders",
    data,
  });

export const createProductionOrdersQuery = (data: {
  items: {
    id: number;
    indexId: number;
    number: number;
    manufacturer: number | null;
    isSelfProduced: boolean;
    order: number;
    externalNumber: string;
  }[];
}) =>
  queryFetch<{
    status: string;
    manufacturersOrders: Order["recentManufacturersOrders"];
    productionOrders: Order["recentProductionOrders"][number];
  }>({
    method: "POST",
    url: "/production-orders/create-production-orders",
    data,
  });

export const getItemsForRequestProductionOrder = (id: string | number, abortToken?: string) =>
  api<ProductionOrderIndex[]>({
    method: "GET",
    url: "/production-orders/request-production-order?productionOrder=" + id,
    abortToken,
  });

export const requestProductionOrder = (data: {
  productionOrder: number;
  upholsteries: ProductionOrderIndex["id"][];
}) =>
  api<{ message: string }>({
    method: "POST",
    url: "/production-orders/request-production-order",
    data,
  });

export const createProductionOrder = (data: ProductionOrdersCreationByHand) =>
  api<{
    status: string;
    manufacturersOrders: Order["recentManufacturersOrders"];
    productionOrders: Order["recentProductionOrders"][number];
  }>({
    method: "POST",
    url: "/production-orders/create-production-orders",
    data,
  });

export const getManufacturerOrderLinkInfo = (orderId: string, token: string) =>
  apiFetch<ManufacturerOrderLinkInfo>({
    method: "GET",
    url: `/production-orders/order/${orderId}/${token}`,
  });

export const confirmManufacturerOrder = (orderId: string, token: string) =>
  apiFetch<{ message: string }>({
    method: "POST",
    url: `/production-orders/order/${orderId}/${token}`,
    data: {
      accessToken: token,
    },
  });

export const manufacturerOrderFileFactory = (() => {
  function formatSignature(signature: string) {
    return signature.replace(/\//g, "-");
  }
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }

  return {
    manufacturerOrderPdf: (order: Pick<ManufacturerOrder, "id" | "signature">) => ({
      url: `/production-orders/for-manufacturer/${order.id}/production-pdf`,
      name: `${formatSignature(order.signature)}-${getDate()}`,
    }),
  };
})();

export const postOrderReverseSettle = (id: number) =>
  queryFetch<{ message: "success" }>({
    method: "POST",
    url: `/orders/items/${id}/reverse-settle`,
  });
